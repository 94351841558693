import { isAbsenceWithoutValueHidden } from '@app/reducers/orm/absence/absence.helper';
import { Dictionary } from '@ngrx/entity';
import { addDays, endOfDay, isValid, parse, startOfDay } from 'date-fns';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';

import {
  SCHEDULE_MIN_FILLER_HEIGHT,
  SCHEDULE_ROW_HEIGHT,
  TOTALS_SIZE,
} from '../../../+authenticated/+schedule/employee/schedule-employee-n-period/schedule-constants';
import { hexToRGB, isColorDark } from '../../../shared/contrast.helper';
import { DropZoneData } from '../../../shared/drag-drop/models/drag-drop-data.model';
import { PermissionState } from '../../auth/auth.model';
import { hasPermission } from '../../auth/permission.helper';
import { AbsenceModel, AbsenteeDay } from '../../orm/absence/absence.model';
import { AvailabilityModel, AvailabilityType } from '../../orm/availability/availability.model';
import { EmployeeModel } from '../../orm/employee/employee.model';
import { OpenShiftModel } from '../../orm/open-shift/open-shift.model';
import { RequiredShiftModel } from '../../orm/required-shift/required-shift.model';
import { ConflictTopic, ScheduleConflictStoreModel } from '../../orm/schedule-conflict/schedule-conflict.model';
import { ScheduleModel } from '../../orm/schedule/schedule.model';
import { ShiftModel } from '../../orm/shift/shift.model';
import { ScheduleFilterState } from '../../page-filters/page-filters.model';
import { convertArrayToObject } from '../../shared/entity.helper';
import { EnhancedDepartmentDataNModel } from '../departmentdata/department-data.n.model';
import { calculateAbsenceTotal, calculateScheduleRowHeight, calculateScheduleTotal } from '../schedule-shared.helper';
import { EnhancedTeamDataModel, TeamDataRowModel } from '../teamdata/team-data.n.model';
import {
  EmployeeRowData,
  EnhancedScheduleNModel,
  NTotalDay,
  ScheduleData,
  ScheduleNModel,
  ScheduleRowType,
} from './schedule.n.model';

export const enhanceEmployeeData = (
  employeeData: EnhancedScheduleNModel,
  totals,
  teamId: string,
  departmentId: string,
  scheduleFilters: ScheduleFilterState,
  cocInSchedule: boolean,
  scheduleConflict: ScheduleConflictStoreModel,
  employeeTeams: string[],
  employeeEntities: Dictionary<EmployeeModel>
): EmployeeRowData => {
  let filteredSchedules = employeeData.filteredSchedules;
  let rowHeight = employeeData.rowHeight;
  if (scheduleFilters?.period.periodType === 'week' && scheduleFilters?.showOptions?.totals) {
    rowHeight -= TOTALS_SIZE / 2;
  }
  if (!scheduleFilters.showOptions.loanedShifts) {
    const updatedRowData = recalculateRowHeightForLoanedShifts(teamId, employeeData, scheduleFilters, totals);

    if (updatedRowData.hasDifference) {
      filteredSchedules = updatedRowData.filteredSchedules;
      rowHeight = updatedRowData.rowHeight;
    }
  }

  const absences: AbsenceModel[] = scheduleFilters?.showTypes?.absence
    ? employeeData.absence?.filter((absence: AbsenceModel) => absence.status !== 'Declined')
    : [];
  const availabilities: AvailabilityModel[] = employeeData.availabilities;
  const employeeTotal = {
    hours: 0,
    pay: 0,
  };

  const originalTotals = {
    hours: 0,
    pay: 0,
  };

  const updatedTotals: NTotalDay[] = totals.map((total): NTotalDay => {
    const dayOriginalSchedules = filterScheduleForDate(total.date, employeeData.schedules);
    const dayOriginalTotals = calculateScheduleTotal(dayOriginalSchedules, cocInSchedule);
    originalTotals.hours += dayOriginalTotals.hours;
    originalTotals.pay += dayOriginalTotals.pay;

    const daySchedules = filterScheduleForDate(total.date, filteredSchedules);
    const dayAbsences = filterDayAbsenceForDate(total.date, absences, employeeEntities);

    const hasDayAvailabilityConflict = daySchedules.some((schedule) => {
      if (!scheduleConflict) {
        return false;
      }

      const conflict = groupBy(scheduleConflict?.conflicts[schedule.occurrence_id], 'topic');
      return conflict[ConflictTopic.AVAILABILITY]?.length > 0;
    });
    const dayAvailabilities = filterAndEnhanceAvailabilitiesForDate(
      total.date,
      availabilities,
      hasDayAvailabilityConflict
    );

    const scheduleTotals = calculateScheduleTotal(daySchedules, cocInSchedule, teamId);
    const absencesForTotals = dayAbsences.filter(
      (absence: AbsenceModel) =>
        employeeTeams.includes(teamId) && absence.AbsenteeDay[total.date]?.department_id === departmentId
    );

    let absenceTotals = calculateAbsenceTotal(
      scheduleFilters?.showTypes?.absence ? absencesForTotals : [],
      total.date,
      departmentId
    );

    const absenceTotalsForEmployeeHours = calculateAbsenceTotal(dayAbsences, total.date);
    originalTotals.hours += absenceTotalsForEmployeeHours.hours;
    originalTotals.pay += absenceTotalsForEmployeeHours.pay;

    if (!scheduleFilters?.showTypes?.absence) {
      absenceTotals = {
        hours: 0,
        pay: 0,
      };
    }

    const accumulatedTotals = {
      hours: scheduleTotals.hours + absenceTotals.hours,
      pay: scheduleTotals.pay + absenceTotals.pay,
    };

    employeeTotal.hours += accumulatedTotals.hours;
    employeeTotal.pay += accumulatedTotals.pay;

    return {
      date: total.date,
      id: employeeData.id,
      schedules: sortBy(daySchedules, ['date', 'starttime']),
      absences: dayAbsences,
      availabilities: dayAvailabilities,
      totals: accumulatedTotals,
      absenceTotals,
    };
  });

  if (rowHeight < SCHEDULE_ROW_HEIGHT) {
    rowHeight = SCHEDULE_ROW_HEIGHT;
  }

  return {
    totalsPerDay: updatedTotals,
    id: employeeData.id,
    totals: employeeTotal,
    originalTotals,
    rowHeight,
  };
};

export const filterAndEnhanceAvailabilitiesForDate = (
  date: string,
  availabilities: AvailabilityModel[],
  hasDayAvailabilityConflict: boolean
) =>
  availabilities
    .filter((availability: AvailabilityModel) => availability.date === date)
    .map(enhanceAvailabilities(hasDayAvailabilityConflict));

export const filterDayAbsenceForDate = (
  date: string,
  absences: AbsenceModel[],
  employeeEntities: Dictionary<EmployeeModel>
) =>
  absences.filter((absence: AbsenceModel) => {
    if (absence.status === 'Declined') {
      return false;
    }

    const day: AbsenteeDay = absence.AbsenteeDay[date];

    if (!day) {
      return false;
    }

    if (isAbsenceWithoutValueHidden(absence, day)) {
      return false;
    }

    const endDate = employeeEntities[absence.user_id].enddate;

    return !endDate || day.date < endDate;
  });

const filterScheduleForDate = (date: string, schedules: ScheduleModel[]) =>
  schedules.filter((schedule) => schedule.date === date);

export const recalculateRowHeightForLoanedShifts = (
  teamId: string,
  employeeData: EnhancedScheduleNModel | ScheduleNModel,
  scheduleFilters: ScheduleFilterState,
  totals
) => {
  const filteredSchedules = filterOutLoanedShifts(teamId, employeeData.filteredSchedules);
  const hasDifference = filteredSchedules.length !== employeeData.filteredSchedules.length;

  if (!hasDifference) {
    return {
      hasDifference,
    };
  }

  let rowHeight = calculateScheduleRowHeight(
    {
      schedules: filteredSchedules,
      absence: employeeData.absence,
      availabilities: employeeData.availabilities,
    },
    totals,
    scheduleFilters?.showTypes,
    scheduleFilters?.showOptions
  );

  if (rowHeight < SCHEDULE_ROW_HEIGHT) {
    rowHeight = SCHEDULE_ROW_HEIGHT;
  }

  if (scheduleFilters?.period.periodType === 'week' && scheduleFilters?.showOptions?.totals) {
    rowHeight -= TOTALS_SIZE / 2;
  }

  return {
    filteredSchedules,
    rowHeight,
    hasDifference,
  };
};
export const enhanceTeamOpenShift = (teamData: EnhancedTeamDataModel, totals) => {
  const openShifts = teamData.openShifts.map((openShift: any) => ({
    ...openShift,
    instances_remaining: parseInt(openShift.instances_remaining, 10),
  }));

  const updatedTotals = totals.map((total) => {
    const filteredOpenShifts = openShifts.filter((shift: any) => shift.date === total.date);

    return {
      date: total.date,
      id: teamData.id,
      openShifts: sortBy(filteredOpenShifts, ['date', 'starttime']),
    };
  });

  return {
    totalsPerDay: convertArrayToObject('date', updatedTotals),
    id: teamData.id,
    isLoading: false,
    openShiftsRowHeight: teamData.openShiftsRowHeight,
    permissions: teamData?.permissions,
  };
};

export const enhanceRequiredShiftsForNewSchedule = (
  teamData: EnhancedTeamDataModel | EnhancedDepartmentDataNModel,
  shiftEntities: Dictionary<ShiftModel>,
  totals
): TeamDataRowModel => {
  const requiredShifts = teamData.requiredShifts.map((requiredShift: any) => enhanceRequiredShift(requiredShift));

  const updatedTotals = totals.map((total) => {
    const filteredRequiredShifts = requiredShifts.filter((shift: any) => shift.date === total.date);

    return {
      date: total.date,
      id: teamData.id,
      requiredShifts: sortBy(filteredRequiredShifts, ['date', 'starttime']),
    };
  });

  return {
    totalsPerDay: convertArrayToObject('date', updatedTotals),
    id: teamData.id,
    requiredShiftsRowHeight: teamData.requiredShiftsRowHeight,
    permissions: teamData?.permissions,
  };
};

export const enhanceRequiredShift = (requiredShift) => {
  let startDateTime = parse(requiredShift.date + ' ' + requiredShift.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
  let endDateTime = parse(requiredShift.date + ' ' + requiredShift.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

  if (requiredShift.time_settings === 'any') {
    startDateTime = startOfDay(startDateTime);
    endDateTime = endOfDay(endDateTime);
  } else {
    if (endDateTime < startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }
  }

  return {
    ...requiredShift,
    startDateTime,
    endDateTime,
  };
};

export const enhanceAvailabilities = (hasConflict: boolean) => (availability: AvailabilityModel) => {
  const allDay =
    availability.type === AvailabilityType.AVAILABLE_ALL_DAY ||
    availability.type === AvailabilityType.UNAVAILABLE_ALL_DAY;

  const starttime = allDay ? '00:00:00' : availability.starttime;
  const endtime = allDay ? '00:00:00' : availability.endtime;

  const startDateTime = parse(availability.date + ' ' + starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
  let endDateTime = parse(availability.date + ' ' + endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

  if (endDateTime <= startDateTime) {
    endDateTime = addDays(endDateTime, 1);
  }

  const available =
    availability.type === AvailabilityType.AVAILABLE_ALL_DAY || availability.type === AvailabilityType.AVAILABLE_FROM;

  const color = available ? '#8ad646' : '#98A0AB'; // green / gray
  const color_rgb = hexToRGB(color);
  const color_is_dark = isColorDark(color);
  return {
    ...availability,
    color,
    color_rgb,
    color_is_dark,
    hasConflict,
    startDateTime,
    endDateTime,
  };
};

export const enhanceOpenAndRequiredShiftsWithPermissions = (
  requiredShifts: RequiredShiftModel[],
  openShifts: OpenShiftModel[],
  permissionState: PermissionState,
  shiftEntities: Dictionary<ShiftModel>
) => {
  const openShiftsWithPermissions = openShifts.map((openShift: OpenShiftModel) => ({
    ...openShift,
    Shift: shiftEntities[openShift.shift_id],
    permissions: {
      canEdit: hasPermission(
        {
          permissions: ['Edit roster'],
          userId: 'me',
          departments: openShift.department_id,
        },
        permissionState
      ),
      canCreate: hasPermission(
        {
          permissions: ['Create roster'],
          userId: 'me',
          departments: openShift.department_id,
        },
        permissionState
      ),
      canDelete: hasPermission(
        {
          permissions: ['Delete roster'],
          userId: 'me',
          departments: openShift.department_id,
        },
        permissionState
      ),
    },
  }));
  const requiredShiftsWithPermissions = requiredShifts.map((requiredShift: RequiredShiftModel) => ({
    ...requiredShift,
    Shift: shiftEntities[requiredShift.shift_id],
    permissions: {
      canEdit: hasPermission(
        {
          permissions: ['Edit required shift'],
          userId: 'me',
          departments: requiredShift.department_id,
        },
        permissionState
      ),
      canCreate: hasPermission(
        {
          permissions: ['Create required shift'],
          userId: 'me',
          departments: requiredShift.department_id,
        },
        permissionState
      ),
      canDelete: hasPermission(
        {
          permissions: ['Delete required shift'],
          userId: 'me',
          departments: requiredShift.department_id,
        },
        permissionState
      ),
    },
  }));
  return {
    openShifts: openShiftsWithPermissions,
    requiredShifts: requiredShiftsWithPermissions,
  };
};

export const calculateScheduleHeights = (data: ScheduleData[]): ScheduleData[] => {
  let accumulatedHeight = 0;
  let departmentHeight = 0;
  return data.map((scheduleData: ScheduleData, index: number): ScheduleData => {
    let rowHeight = index > 1 ? scheduleData.rowHeight : 0;
    if (scheduleData.type === ScheduleRowType.DEPARTMENT_HEADER) {
      departmentHeight = 0;
    }

    if (scheduleData.type !== ScheduleRowType.FILLER) {
      departmentHeight += scheduleData.rowHeight;
    }

    if (scheduleData.type === ScheduleRowType.FILLER) {
      rowHeight = calculateFillerHeight(departmentHeight);
    }
    accumulatedHeight = accumulatedHeight + rowHeight;
    return {
      ...scheduleData,
      rowHeight,
      accumulatedHeight,
    };
  });
};

const calculateFillerHeight = (lastDepartmentHeight: number) => {
  /*
   This number represents the top of the virtual scroll viewport.
   This includes the offset number to make sure the last department header goes perfectly under the static header
   */
  const windowHeight = window.innerHeight - 154;
  let fillerHeight = 0;

  if (windowHeight > lastDepartmentHeight) {
    fillerHeight = windowHeight - lastDepartmentHeight;
  }

  // To fake a padding bottom for the intercom chat launcher =)
  if (fillerHeight < SCHEDULE_MIN_FILLER_HEIGHT) {
    fillerHeight = SCHEDULE_MIN_FILLER_HEIGHT;
  }

  return fillerHeight;
};

export const filterOutLoanedShifts = (teamId: string, schedules: ScheduleModel[]) =>
  schedules.filter((schedule: ScheduleModel) => teamId === schedule.team_id);

export const enhanceScheduleWithShift = (schedules: ScheduleModel[], shifts: Dictionary<ShiftModel>) =>
  schedules.map((schedule: ScheduleModel) => {
    let startDateTime = parse(schedule.date + ' ' + schedule.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
    if (!isValid(startDateTime)) {
      startDateTime = parse(schedule.date + ' ' + schedule.starttime, 'yyyy-MM-dd HH:mm', new Date());
    }

    let endDateTime = parse(schedule.date + ' ' + schedule.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());
    if (!isValid(endDateTime)) {
      endDateTime = parse(schedule.date + ' ' + schedule.endtime, 'yyyy-MM-dd HH:mm', new Date());
    }

    if (endDateTime <= startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }
    return {
      ...schedule,
      Shift: shifts[schedule.shift_id],
      startDateTime,
      endDateTime,
    };
  });

// Determine whether we moved the same shift on top of the shadow version of the shift
export const shouldMoveLoaned = (draggingShift: any, droppedOnShift: DropZoneData): boolean => {
  let loaned = draggingShift.loaned !== undefined ? draggingShift.loaned : false;

  return (
    loaned && draggingShift.user_id === droppedOnShift.employeeId && draggingShift.team_id !== droppedOnShift.teamId
  );
};
