import { ScheduleModel } from '../../orm/schedule/schedule.model';
import { AbsenceModel } from '../../orm/absence/absence.model';
import { DateRange, Totals } from '../../../shared/interfaces';
import { Dictionary } from '@ngrx/entity';
import { AvailabilityModel } from '../../orm/availability/availability.model';

export interface ScheduleNModel {
  id: string;
  schedules: Dictionary<ScheduleModel>;
  absence: Dictionary<AbsenceModel>;
  availabilities: Dictionary<AvailabilityModel>;
  filteredSchedules?: ScheduleModel[];
  scheduleTeams?: string[];
  rowHeight?: number;
}

export interface EnhancedScheduleNModel {
  id: string;
  absence: AbsenceModel[];
  schedules: ScheduleModel[];
  availabilities: AvailabilityModel[];
  filteredSchedules: ScheduleModel[];
  rowHeight?: number;
}

export interface ScheduleItemModel {
  id: string;
  date?: string;
  occurrence_id?: string;
  loading?: boolean;
  isConflicted?: boolean;
  permissions: {
    canDelete: boolean;
    canEdit: boolean;
    canCreate: boolean;
  };
}

export interface EmployeeRowData {
  totalsPerDay: NTotalDay[];
  id: string;
  totals?: Totals;
  originalTotals?: Totals;
  rowHeight: number;
  absenceHeight?: number;
  scheduleHeight?: number;
  availabilitiesHeight?: number;
}
export interface NTotalDay {
  date: string;
  absences: AbsenceModel[];
  availabilities: AvailabilityModel[];
  totals: Totals;
  absenceTotals: Totals;
  schedules: ScheduleModel[];
  id?: string;
}

export interface ScheduleData {
  id?: string;
  type: ScheduleRowType;
  data?: any;
  rowHeight: number;
  accumulatedHeight?: number;
  lastRow?: boolean;
  hasData?: boolean;
}

export enum ScheduleRowType {
  EMPLOYEE,
  OPEN_SHIFT,
  REQUIRED_SHIFT,
  EVENT,
  TEAM_HEADER,
  TEAM_TOTALS,
  DEPARTMENT_TOTALS,
  PLACEHOLDER,
  DEPARTMENT_HEADER,
  TEAM_BUDGET,
  TEAM_NOTES,
  DEPARTMENT_FORECAST,
  WEATHER,
  DEPARTMENT_REQUIRED_SHIFT,
  PUBLISHED_STATUS,
  DEPARTMENT_BUDGET,
  DEPARTMENT_PERIOD_ROW,
  FILLER,
  TEAM_SCHEDULES,
  TEAM_ABSENCE,
  TEAM_AVAILABILITY,
}

export interface ScheduleTotals {
  totals: Totals;
  totalsPerDay: TotalPerDay[];
  rowHeight: number;
}

export interface TotalPerDay {
  totals: Totals;
  date: string;
  range: DateRange;
}

export interface OptimizedStoreItemData {
  departmentId?: string;
  teamId?: string;
  userId?: string;
  recurring?: boolean;
}

export interface ScheduleDepartmentPermissions {
  id: string;
  canViewBudget: boolean;
  canViewRequiredShifts: boolean;
  canViewLogs: boolean;
  canViewForecast: boolean;
  canViewTeamNotes: boolean;
}
